import { combineReducers } from "redux";

import coreReducer from "~/modules/_core/state/coreSlice";
import settingReducer from "~/modules/_core/state/settingsSlice";
import otpConfigsReducer from "~/modules/login/state/otpConfigsSlice";
import tripReducer from "~/modules/tripDetails/state/tripSlice";
import tripsScheduleReducer from "~/modules/tripsSchedule/state/tripsScheduleSlice";

const rootReducer = combineReducers({
  ...settingReducer,
  ...coreReducer,
  ...otpConfigsReducer,
  ...tripsScheduleReducer,
  ...tripReducer,
});

export default rootReducer;
