import {
  RouteDirectionality,
  StopForBaBd,
  TripStatus,
} from "~/gql/main/types.generated";

type State = {
  trips: {
    [id: string]: {
      directionality: RouteDirectionality;
      name: string;
      stops: StopForBaBd[];
      status: TripStatus;
      routeId: string;
      date: string;
      studentsOnLeaveCount: number;
      tripEndOffsetMinutes: number;
      tripStartOffsetMinutes: number;
      manualAttendanceEndOffsetMinutes: number;
      manualAttendanceStartOffsetMinutes: number;
      plannedEndTime: Date;
      plannedStartTime: Date;
    };
  };
};
const initialState: State = { trips: {} };
export default initialState;
