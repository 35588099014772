import { Stack, Typography } from "@mui/material";
import Image from "next/image";

import useCopy from "~/modules/_core/locale";

import notFoundImage from "~/public/assets/notFound.svg";

const ErrorPage404 = () => {
  const { translate } = useCopy();
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Stack spacing={3} alignItems="center">
        <Image
          src={notFoundImage.src}
          alt="404 image"
          objectFit="contain"
          width={440}
          height={280}
        />
        <Typography variant="h6">{translate("pageNotFound")}</Typography>
      </Stack>
    </Stack>
  );
};

export default ErrorPage404;
