import { AuthRoles } from "~/utils/authToken";

import { WhoAmIQuery } from "~/core/generated/main/get-one.generated";

// declaring the types for our state
type UserState = {
  currentUser: {
    accessToken: string;
    data: WhoAmIQuery["whoAmI"] | undefined;
  };
  roles: AuthRoles[] | undefined;
};
const initialState: UserState = {
  currentUser: {
    accessToken: "",
    data: undefined,
  },
  roles: [],
};
export default initialState;
