import { Country, CountryCode, Language } from "~/gql/main/types.generated";

type State = {
  darkTheme: boolean;
  dir: "ltr" | "rtl";
  lang: Language;
  countries: {
    [key in CountryCode]?: Country;
  };
};
const initialState: State = {
  darkTheme: false,
  dir: "ltr",
  lang: Language.EN,
  countries: {},
};
export default initialState;
