/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "~/state/store";

import initialState from "~/modules/login/state/otpConfigsSlice/initState";

const REDUCER_NAME = "login";

export const otpConfigsSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setOtpExpiryTime: (state, action) => {
      state.otpExpiryTime = action.payload;
    },
    setLastLoginAttempt: (state, action) => {
      state.lastLoginAttempt = action.payload;
    },
    resetLastLoginAttempt: (state) => {
      state.lastLoginAttempt = {
        phone: "",
        errorCode: "",
        attempts: 0,
        timer: 0,
        timestamp: "",
      };
    },
  },
});
export const getOtpExpiryTime = (state: RootState) =>
  state?.[REDUCER_NAME]?.otpExpiryTime || initialState.otpExpiryTime;

export const getLastLoginAttempt = (state: RootState) =>
  state?.[REDUCER_NAME]?.lastLoginAttempt || initialState.lastLoginAttempt;

export const { setOtpExpiryTime, setLastLoginAttempt, resetLastLoginAttempt } =
  otpConfigsSlice.actions;

const reducer = { [REDUCER_NAME]: otpConfigsSlice.reducer };

export default reducer;
