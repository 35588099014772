import {
  alpha,
  createTheme,
  PaletteOptions,
  Theme,
} from "@mui/material/styles";

import config from "~/config/appConfig";

import { darkPalette, lightPalette } from "~/styles/palette";
import { typography } from "~/styles/typography";

declare module "@mui/material/styles" {
  interface Theme {
    dir?: string;
    direction?: string;
  }

  interface ThemeOptions {
    dir?: string;
    direction?: string;
  }

  interface Palette {
    tertiary?: Palette["primary"];
    natural?: Palette["primary"];
    chalk?: Palette["primary"];
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions["primary"];
    natural?: PaletteOptions["primary"];
    chalk?: PaletteOptions["primary"];
  }

  interface PaletteColor {
    lightest?: string;
    darkest?: string;
  }

  interface SimplePaletteColorOptions {
    lightest?: string;
    darkest?: string;
  }

  interface TypeBackground {
    base?: string;
  }
}

declare module "@mui/material" {
  interface ChipPropsColorOverrides {
    tertiary: true;
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsVariantOverrides {
    disabled: true;
  }
}

// Create a theme instance.
export default function theme({ rtl = false, darkTheme = false }) {
  const palette = darkTheme ? darkPalette : lightPalette;
  const styles = {
    dir: rtl ? "rtl" : "ltr",
    direction: rtl ? "rtl" : "ltr",
    palette: {
      mode: darkTheme ? "dark" : ("light" as PaletteOptions["mode"]),
      ...palette,
    },
    typography,
    components: {
      MuiBackdrop: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            backgroundColor: "rgba(0,0,0,0.1)",
            zIndex: theme.zIndex.drawer + 1,
          }),
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: (palette as typeof lightPalette).natural.lightest,
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        variants: [
          {
            props: { disabled: true },
            style: {
              backgroundColor: alpha(
                (palette as typeof lightPalette).chalk.main,
                1
              ),
              input: {
                cursor: "not-allowed",
              },
            },
          },
        ],
      },

      MuiPaper: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root.Mui-expanded": {
              margin: 0,
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: theme.spacing(1.5, 0),
            },
            "& .MuiButtonBase-root.Mui-expanded": {
              minHeight: 0,
            },
          }),
        },
      },

      MuiButton: {
        styleOverrides: {
          root: { textTransform: "uppercase" as const },
          outlined: {
            "&.greyButton": {
              color: "#52606d",
              borderColor: "rgba(82, 96, 109, 0.5)",

              "&:hover": {
                color: "#52606d",
                borderColor: "rgba(82, 96, 109, 0.5)",
                backgroundColor: "rgba(214, 214, 214, 0.2)",
              },
            },
          },
          contained: {
            "&.Mui-disabled.set-attendance-btn": {
              color: "#606a7d",
              backgroundColor: "#c4cbd7",
            },
          },
        },
      },

      MuiModal: {
        styleOverrides: {
          root: {
            width: "100%",
            height: "100%",
            transition: "all 0.2s ease-in-out",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            width: "100%",
            height: "100%",
            transition: "all 0.2s ease-in-out",
          },
        },
      },

      MuiAlert: {
        styleOverrides: {
          // message: ({ ownerState }: { ownerState: any }) => ({
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "space-between",
          //   alignSelf: "center",
          //   flexDirection: ownerState.severity === "success" ? "column" : "row",
          // }),
          // action: ({ ownerState }: { ownerState: any }) => ({
          //   alignItems:
          //     ownerState.severity === "error" ? "center" : "flex-start",
          //   paddingTop: ownerState.severity === "error" ? "0px" : "6px",
          //   alignSelf: "center",
          //   padding: "8px 0",
          // }),
          // override the background colors to avoid lightening the background by MUI
          standardInfo: {
            backgroundColor: (palette as typeof lightPalette).info.light,
            color: (palette as typeof lightPalette).info.dark,
            "& .MuiAlert-icon": {
              color: (palette as typeof lightPalette).info.dark,
            },
          },
          outlinedInfo: {
            borderColor: (palette as typeof lightPalette).info.main,
            color: (palette as typeof lightPalette).info.main,
            "& .MuiAlert-icon": {
              color: (palette as typeof lightPalette).info.dark,
            },
          },
          standardSuccess: {
            color: (palette as typeof lightPalette).success.main,
            backgroundColor: (palette as typeof lightPalette).success.light,
            "& .MuiAlert-icon": {
              color: (palette as typeof lightPalette).success.main,
            },
          },
          standardError: {
            color: (palette as typeof lightPalette).error.main,
            backgroundColor: (palette as typeof lightPalette).error.light,
            "& .MuiAlert-icon": {
              color: (palette as typeof lightPalette).error.main,
            },
          },
          // TODO: override other variants because MUI adds opacity to 'light' color which is already light enough
          // outlinedInfo: {
          //   color: (palette as typeof lightPalette).info.main,
          //   // borderColor: (palette as typeof lightPalette).info.light,
          // },
          // infoIconColor: {
          //   color: (palette as typeof lightPalette).info.main,
          // },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          // root: {
          //   borderColor: "#DDE3EE",
          //   paddingY: 1.5,
          //   fontSize: "21px",
          //   letterSpacing: "0",
          //   "&:hover": {
          //     backgroundColor: "transparent",
          //   },
          //   "&.Mui-selected.wrong-selected:hover, &.Mui-selected.wrong-selected":
          //     {
          //       backgroundColor: "#af313b1a",
          //       color: "#af313b",
          //     },
          //   "&.Mui-selected, &.Mui-selected:hover": {
          //     backgroundColor: "#00aea41a",
          //     color: "#007a73",
          //   },
          // },
        },
      },
      MuiDatePicker: {
        defaultProps: {
          // Override the default date format
          inputFormat: config.settings.defaultDateFormat,
        },
      },
      MuiDesktopDatePicker: {
        defaultProps: {
          // Override the default date format
          inputFormat: config.settings.defaultDateFormat,
        },
      },
      MuiDateTimePicker: {
        defaultProps: {
          // Override the default date format
          inputFormat: config.settings.defaultDateTimeFormat,
          ampm: false,
        },
      },
      MuiDesktopDateTimePicker: {
        defaultProps: {
          // Override the default date format
          inputFormat: config.settings.defaultDateTimeFormat,
          ampm: false,
        },
      },
      MuiTimePicker: {
        defaultProps: {
          // Override the default date format
          inputFormat: config.settings.defaultTimeFormat,
          ampm: false,
        },
      },
      MuiDesktopTimePicker: {
        defaultProps: {
          // Override the default date format
          inputFormat: config.settings.defaultTimeFormat,
          ampm: false,
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontWeight: 600,
          },
        },
      },
      EmCountryChip: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            width: "max-content",
            height: "27px",
            backgroundColor: theme.palette.chalk?.lightest,
            border: "none",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: theme.palette.chalk?.light,
            },
            color: theme.palette.chalk?.dark,
            fontSize: typography?.subtitle2?.fontSize,
            lineHeight: typography?.body2?.lineHeight,
            "& .MuiChip-avatar": {
              width: 20,
              height: 20,
            },
          }),
        },
      },
      EmStatusChip: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.chalk?.lightest,
            color: theme.palette.chalk?.dark,
          }),
          filled: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.chalk?.lightest,
            color: theme.palette.chalk?.dark,
          }),
          outlined: ({ theme }: { theme: Theme }) => ({
            backgroundColor: "transparent",
            borderColor: theme.palette.chalk?.dark,
            color: theme.palette.chalk?.dark,
          }),
          filledPrimary: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.primary?.lightest,
            color: theme.palette.primary?.main,
          }),
          filledSecondary: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.secondary?.lightest,
            color: theme.palette.secondary?.main,
          }),
          filledTertiary: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.tertiary?.lightest,
            color: theme.palette.tertiary?.main,
          }),
          filledSuccess: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.success?.light,
            color: theme.palette.success?.main,
          }),
          filledError: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.error?.light,
            color: theme.palette.error?.main,
          }),
          filledWarning: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.warning?.lightest,
            color: theme.palette.warning?.main,
          }),
          filledInfo: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.info?.lightest,
            color: theme.palette.info?.main,
          }),
          outlinedPrimary: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.palette.primary?.main,
            color: theme.palette.primary?.main,
          }),
          outlinedSecondary: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.palette.secondary?.main,
            color: theme.palette.secondary?.main,
          }),
          outlinedTertiary: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.palette.tertiary?.main,
            color: theme.palette.tertiary?.main,
          }),
          outlinedSuccess: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.palette.success?.main,
            color: theme.palette.success?.main,
          }),
          outlinedError: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.palette.error?.main,
            color: theme.palette.error?.main,
          }),
          outlinedWarning: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.palette.warning?.light,
            color: theme.palette.warning?.dark,
          }),
          outlinedInfo: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.palette.info?.main,
            color: theme.palette.info?.main,
          }),
        },
      },
    },
  };
  return createTheme({ ...styles });
}
