/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "~/state/store";

import initialState from "~/modules/_core/state/coreSlice/initState";

const REDUCER_NAME = "user";

export const userSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser.data = action.payload;
    },
    removeCurrentUser: (state) => {
      state.currentUser.data = undefined;
    },
    setAccessToken: (state, action) => {
      state.currentUser.accessToken = action.payload;
    },
    removeAccessToken: (state) => {
      state.currentUser.accessToken = "";
    },
    setUserRoles: (state, action) => {
      state.roles = action.payload;
    },
    removeUserRoles: (state) => {
      state.roles = [];
    },
  },
});

export const getCurrentUser = (state: RootState) =>
  state?.[REDUCER_NAME]?.currentUser?.data;

export const getUserRoles = (state: RootState) => state?.[REDUCER_NAME]?.roles;
export const getAccessToken = (state: RootState) =>
  state?.[REDUCER_NAME]?.currentUser?.accessToken;

export const {
  setCurrentUser,
  removeCurrentUser,
  setAccessToken,
  removeAccessToken,
  setUserRoles,
  removeUserRoles,
} = userSlice.actions;

const reducer = { [REDUCER_NAME]: userSlice.reducer };

// exporting the reducer here, as we need to add this to the store
export default reducer;
