import { ReactElement, useEffect, useLayoutEffect } from "react";
import { Provider, useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AppProps } from "next/app";
import Head from "next/head";
import NextNprogress from "nextjs-progressbar";
import { ApolloProvider } from "@apollo/client";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { PersistGate } from "redux-persist/integration/react";

import { setCurrentUserData } from "~/utils/accessControl/currentUser";
import { useLogout } from "~/utils/accessControl/useLogout";
import { AuthRoles } from "~/utils/authToken";
import { configureSentryUser } from "~/utils/sentry";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { persistor, RootState, store } from "~/state/store";

import { client } from "~/gql/main/apolloClient";

import {
  createEmotionCache,
  createEmotionCacheRtl,
} from "~/styles/createEmotionCache";
import theme from "~/styles/theme";

import useCopy from "~/modules/_core/locale";
import { getAccessToken } from "~/modules/_core/state/coreSlice";

import ErrorBoundary from "~/core/components/ErrorBoundary";
import {
  useWhoAmILazyQuery,
  WhoAmIQuery,
} from "~/core/generated/main/get-one.generated";

// import "~/utils/i18n/init";
import "~/styles/globals.css";
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
const clientSideEmotionCacheRtl = createEmotionCacheRtl();

const WrapperComp = (props: WrapperCompTypes): ReactElement => {
  const { Component, pageProps } = props;
  const dispatch = useAppDispatch();

  const { translate } = useCopy();
  const dir = useAppSelector((state) => state.settings.dir);
  const accessToken = useAppSelector(getAccessToken);
  const isDarkTheme = useSelector(
    ({ settings }: RootState) => settings.darkTheme
  );
  const logout = useLogout();

  const [getWhoAmI] = useWhoAmILazyQuery({
    fetchPolicy: "no-cache",
    nextFetchPolicy: "standby",
    client,
  });

  const getUserDetails = () => {
    getWhoAmI({
      onCompleted: (data: WhoAmIQuery) => {
        const { roles } = data.whoAmI;
        const roleFound = roles?.find((role) => {
          return (
            role.role.name === AuthRoles.BUS_ATTENDANT ||
            role.role.name === AuthRoles.DRIVER
          );
        });

        if (roleFound) {
          setCurrentUserData(dispatch, data);

          const { email, id } = data.whoAmI;
          configureSentryUser(email, id);
        } else {
          logout();
        }
      },
    });
  };

  useEffect(() => {
    // Bail out if the user is not logged in.
    if (!accessToken) return;

    getUserDetails();

    if (typeof window === "undefined") return;

    window.addEventListener("focus", getUserDetails);

    return () => {
      window.removeEventListener("focus", getUserDetails);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const customTheme = theme({
    rtl: dir === "rtl",
    darkTheme: isDarkTheme,
  });

  useLayoutEffect(() => {
    document.body.setAttribute("dir", dir);
  }, [dir]);

  return (
    <CacheProvider
      value={dir === "rtl" ? clientSideEmotionCacheRtl : clientSideEmotionCache}
    >
      <ThemeProvider theme={customTheme}>
        <ApolloProvider client={client}>
          <Head>
            <title>{translate("saarDriver")}</title>
            <meta
              name="viewport"
              content="initial-scale=0.8, width=device-width"
            />
          </Head>
          <CssBaseline />
          {/* <Script src="https://accounts.google.com/gsi/client" defer async /> */}
          <ErrorBoundary>
            <div dir={dir}>
              <Component {...pageProps} />
            </div>

            <NextNprogress
              color={customTheme?.palette?.primary?.main}
              startPosition={0.3}
              stopDelayMs={0}
              height={4}
            />
          </ErrorBoundary>
        </ApolloProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

// eslint-disable-next-line react/no-multi-comp
export default function MyApp(props: AppProps) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <WrapperComp {...props} />
      </PersistGate>
    </Provider>
  );
}

type WrapperCompTypes = {
  isRtl?: boolean;
  Component?: any;
  pageProps?: any;
  emotionCache?: EmotionCache;
};
