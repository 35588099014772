/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "~/state/store";

import initialState from "~/modules/tripsSchedule/state/tripsScheduleSlice/initState";

const REDUCER_NAME = "tripsSchedule";

export const tripsScheduleSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setTripsSchedule: (state, action) => {
      state.tripsSchedule = action.payload;
    },
    updateTripsSchedule: (state, action) => {
      const newSchedule = state.tripsSchedule.map((trip) => {
        if (trip.id === action.payload.id) {
          return {
            ...trip,
            ...action.payload,
          };
        } else return trip;
      });
      state.tripsSchedule = newSchedule;
    },
  },
});
export const getTripsSchedule = () => (state: RootState) =>
  state?.[REDUCER_NAME]?.tripsSchedule;

export const { setTripsSchedule, updateTripsSchedule } =
  tripsScheduleSlice.actions;

const reducer = { [REDUCER_NAME]: tripsScheduleSlice.reducer };

// exporting the reducer here, as we need to add this to the store
export default reducer;
