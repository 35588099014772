const routes = {
  tripManifest: "/trip-manifest",
  settings: "/settings",
  login: "/login",
  verifyOtp: "/verify-otp",
  trip: "/trip",
  studentDetails: "/student-details",
  tripAttendance: "/trip-attendance",
  flags: "/assets/countryFlags",
  notFound: "/404",
};

export default routes;
