/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "~/state/store";

import { Country, Language } from "~/gql/main/types.generated";

import initialState from "~/modules/_core/state/settingsSlice/initState";

const REDUCER_NAME = "settings";

export const settingsSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.darkTheme = !state.darkTheme;
    },
    setLanguage: (state, action) => {
      state.lang = action.payload;
      state.dir = action.payload === Language.AR ? "rtl" : "ltr";
    },
    setCountries: (state, action) => {
      state.countries = (action.payload as Country[]).reduce(
        (acc, value) => ({ ...acc, [value.code]: value }),
        {}
      );
    },
  },
});
export const isRtl = (state: RootState) => state?.[REDUCER_NAME]?.dir === "rtl";

export const getCountries = (state: RootState) =>
  state?.[REDUCER_NAME]?.countries;

export const { toggleTheme, setLanguage, setCountries } = settingsSlice.actions;

const reducer = { [REDUCER_NAME]: settingsSlice.reducer };

// exporting the reducer here, as we need to add this to the store
export default reducer;
