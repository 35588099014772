import Router, { NextRouter, useRouter } from "next/router";

import { removeCookie } from "~/utils/storageHelpers";

import { useAppDispatch } from "~/state/hooks";
import { AppDispatch, store } from "~/state/store";

import {
  removeAccessToken,
  removeCurrentUser,
  removeUserRoles,
} from "~/modules/_core/state/coreSlice";

import routes from "~/routes/main";

export const clearSessionData = () => {
  store.dispatch(removeCurrentUser());
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const logout = (dispatch: AppDispatch, router: NextRouter = Router) => {
  removeCookie("saarDriverRefreshToken");
  dispatch(removeAccessToken());
  dispatch(removeUserRoles());

  // TODO: fix issue that runs the logout when initializing the app
  router.push(routes.login);
};

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  return () => {
    logout(dispatch, router);
  };
};
