/* eslint-disable no-restricted-imports */
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { NextPage } from "next";
import Image from "next/image";

import ErrorPage404 from "~/pages/404";
import ServerError500 from "~/pages/500";

import generalError from "~/public/assets/generalError.svg";

interface Props {
  statusCode?: number;
  message?: string;
}

const CustomError: NextPage<Props> = ({ statusCode, message }) => {
  if (statusCode === 404) return <ErrorPage404 />;
  else if (statusCode === 500) return <ServerError500 />;
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Stack spacing={3} alignItems="center">
        <Image
          src={generalError.src}
          alt="error image"
          objectFit="contain"
          width={440}
          height={280}
        />
        <Typography variant="h6">
          {message ? message : "Oops! Something went wrong."}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CustomError;
