import { AppDispatch } from "~/state/store";

import { setCurrentUser, setUserRoles } from "~/modules/_core/state/coreSlice";

import { WhoAmIQuery } from "~/core/generated/main/get-one.generated";

export const setCurrentUserData = (
  dispatch: AppDispatch,
  data: WhoAmIQuery
) => {
  // filter out __typename
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
  const { __typename, roles, ...whoAmI } = data.whoAmI;
  const roleNames = roles?.map(({ role }) => role.name) || [];
  const rolesNamesTemp = roleNames;
  dispatch(setCurrentUser({ ...whoAmI, roles: roleNames }));
  dispatch(setUserRoles(rolesNamesTemp));
};
