import { TripForBaBd } from "~/gql/main/types.generated";

export type StateType = {
  tripsSchedule: TripForBaBd[];
};

const initialState: StateType = {
  tripsSchedule: [],
};
export default initialState;
