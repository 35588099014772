import Cookies from "js-cookie";

// **************************** Local Storage *****************************
export const getItemLocal = (key: string) => localStorage?.getItem(key);
export const removeItemLocal = (key: string) => localStorage?.removeItem(key);
export const setItemLocal = (key: string, value: any) =>
  localStorage.setItem(key, value);

// **************************** Session Storage ****************************
export const getItem = (key: string) => sessionStorage?.getItem(key);
export const removeItem = (key: string) => sessionStorage?.removeItem(key);
export const setItem = (key: string, value: any) =>
  sessionStorage.setItem(key, value);

// **************************** Cookies ************************************
export const setCookie = (
  key: string,
  value: any,
  options?: { expires: Date }
) =>
  Cookies.set(key, value, {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    SameSite: "Lax",
    ...options,
  });
export const getCookie = (key: string) => Cookies.get(key);
export const removeCookie = (key: string) => Cookies.remove(key);
