import jwtDecode from "jwt-decode";

export const tokenExpiration = (token: string) => {
  const { exp }: { exp: number } = jwtDecode(token);
  return new Date(exp * 1000);
};

export const parseUserRole = (token: string) => {
  if (!token) return [];

  const { roles }: { roles: AuthRoles[] | undefined } = jwtDecode(token);
  return roles;
};

export enum AuthRoles {
  AGENT = "SAAR-[sS]*",
  SUPER_USER = "SAAR-OPS-SUPER-SUPER",
  DRIVER = "DRIVER",
  BUS_ATTENDANT = "BUS_ATTENDANT",
  SPONSOR = "SPONSOR",
}
