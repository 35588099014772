export type I18nOptions = {
  [key: string]: any;
};

// replace placeholder in between of special character with a value
export const replacePlaceholder = (str: string, options: I18nOptions) => {
  if (!str) {
    console.error("Localization:", "No string provided");
    return;
  }
  let replaced = "";
  // const prefix = "{{";
  // const suffix = "}}";

  const parts = str?.split(/(\{{\w+?\}})/g).map(function (placeholder) {
    replaced = placeholder.replace(/\{{/g, "").replace(/\}}/g, "");

    if (options[replaced]) {
      return options[replaced];
    } else if (options[replaced] === 0) {
      // Workaround to return zero as a value
      return "0";
    } else {
      return replaced;
    }
  });

  return parts?.join("");
};

export const getLocalize = function (
  labels: {
    [x: string]: {
      en: string;
      ar: string;
    };
  },
  key: string,
  options?: I18nOptions,
  lang?: "ar" | "en"
): string {
  const localizedLabel = labels[key]?.[lang || "en"];

  if (localizedLabel) {
    if (options)
      return replacePlaceholder(localizedLabel, options) || localizedLabel;
    else return localizedLabel;
  } else {
    return key;
  }
};
