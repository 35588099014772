export type StateType = {
  lastLoginAttempt: {
    phone: string;
    errorCode: string;
    attempts: number;
    timer: number;
    timestamp: string;
  };
  otpExpiryTime: number;
};

const initialState: StateType = {
  lastLoginAttempt: {
    phone: "",
    errorCode: "",
    attempts: 0,
    timer: 0,
    timestamp: "",
  },
  otpExpiryTime: 0,
};
export default initialState;
