 /* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
import { Component } from "react";

import CustomError from "~/core/components/CustomError";

class ErrorBoundary extends Component<
  { children: any },
  { hasError: boolean; error: any }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error: any) {
    return { error };
  }
  render() {
    const { error } = this.state;
    if (this.state?.hasError) {
      return (
        <CustomError statusCode={error?.statusCode} message={error?.message} />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
