// WARNING: This file is automatically generated. Do not edit.
import * as Types from "../../../../api/gql/main/types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type WhoAmIQueryVariables = Types.Exact<{ [key: string]: never }>;

export type WhoAmIQuery = {
  __typename?: "Query";
  whoAmI: {
    __typename?: "UserWithAccessibleCountries";
    civilId?: string | null;
    dateOfBirth?: any | null;
    email?: string | null;
    firstName?: string | null;
    firstNameAr?: string | null;
    googleId?: string | null;
    id: string;
    lastName?: string | null;
    lastNameAr?: string | null;
    middleName?: string | null;
    middleNameAr?: string | null;
    phone?: string | null;
    addresses?: Array<{
      __typename?: "Address";
      country: { __typename?: "Country"; code: string };
    }> | null;
    roles?: Array<{
      __typename?: "UserRole";
      id: string;
      roleId: string;
      role: { __typename?: "Role"; name: string };
    }> | null;
    agent?: { __typename?: "Agent"; id: string } | null;
  };
};

export const WhoAmIDocument = gql`
  query WhoAmI {
    whoAmI {
      civilId
      dateOfBirth
      email
      firstName
      firstNameAr
      googleId
      id
      lastName
      lastNameAr
      middleName
      middleNameAr
      phone
      addresses {
        country {
          code
        }
      }
      roles {
        id
        role {
          name
        }
        roleId
      }
      agent {
        id
      }
    }
  }
`;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(
  baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(
    WhoAmIDocument,
    options
  );
}
export function useWhoAmILazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(
    WhoAmIDocument,
    options
  );
}
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<
  WhoAmIQuery,
  WhoAmIQueryVariables
>;
