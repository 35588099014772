export type ComposeUrlType = {
  hostname: string;
  protocol: string;
  port: number;
  apiPrefix?: string;
};

export const composeUrl = ({
  hostname,
  protocol,
  // port,
  apiPrefix,
}: ComposeUrlType) => {
  return `${protocol}://${hostname}${apiPrefix}`;
  // return `${protocol}://${hostname}:${port}${apiPrefix}`;
};
