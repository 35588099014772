import * as Sentry from "@sentry/nextjs";

export const configureSentryUser = (
  email?: string | null,
  id?: string | null
): void => {
  if (email && id) {
    Sentry.setUser({
      email,
      id,
    });
  }
};
