import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";

export const createEmotionCache = () => {
  return createCache({
    key: "mui-style-ltr",
    // stylisPlugins: [rtlPlugin]
  });
};

export const createEmotionCacheRtl = () => {
  return createCache({
    key: "mui-style-rtl",
    stylisPlugins: [rtlPlugin],
  });
};
