/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "~/state/store";

import { TripStatus } from "~/gql/main/types.generated";

import initialState from "~/modules/tripDetails/state/tripSlice/initState";

const REDUCER_NAME = "trips";

export const tripSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setTripsMainDetails: (state, action) => {
      const {
        directionality,
        name,
        routeId,
        stops,
        status,
        id,
        date,
        studentsOnLeaveCount,
        tripEndOffsetMinutes,
        tripStartOffsetMinutes,
        manualAttendanceEndOffsetMinutes,
        manualAttendanceStartOffsetMinutes,
        plannedEndTime,
        plannedStartTime,
      } = action.payload;
      state.trips = {
        ...state.trips,
        [id]: {
          directionality,
          name,
          stops,
          status,
          routeId,
          date,
          studentsOnLeaveCount,
          tripEndOffsetMinutes,
          tripStartOffsetMinutes,
          manualAttendanceEndOffsetMinutes,
          manualAttendanceStartOffsetMinutes,
          plannedEndTime,
          plannedStartTime,
        },
      };
    },
    setStudentTempStatus: (state, action) => {
      const { status, tripId, studentId } = action.payload;
      const { stops } = state.trips[tripId];
      stops.map((stop) => {
        // eslint-disable-next-line array-callback-return
        return stop.students.filter((student: any) => {
          if (student.id === studentId) {
            student.tempStatus = status;
          }
        });
      });
    },
    removeStudentTempStatus: (state, action) => {
      const { tripId, updateAttendanceStatus } = action.payload;
      const { stops } = state.trips[tripId];
      stops.map((stop) => {
        // eslint-disable-next-line array-callback-return
        return stop.students.map((currentStudent: any) => {
          delete currentStudent?.tempStatus;
          updateAttendanceStatus?.forEach(
            ({ confirmedAttendanceStatus, student }: any) => {
              if (currentStudent.id === student.id) {
                return (currentStudent.confirmedAttendanceStatus =
                  confirmedAttendanceStatus);
              }
            }
          );
        });
      });
    },
    updateStudentData: (state, action) => {
      const { tripId, studentData } = action.payload;
      const { stops } = state.trips[tripId] || {};

      const newStops = stops?.map((stop) => {
        const newStudents = stop.students.map((currentStudent: any) => {
          if (currentStudent.id === studentData.id) {
            if (currentStudent?.tempStatus) delete currentStudent?.tempStatus;
            return {
              ...currentStudent,
              ...studentData,
            };
          } else return currentStudent;
        });

        return {
          ...stop,
          students: newStudents,
        };
      });

      state.trips[tripId].stops = newStops;
    },
    updateTripData: (state, action) => {
      const { tripId, ...rest } = action.payload;
      state.trips[tripId] = { ...state.trips[tripId], ...rest };
    },
    removeOldTrips: (state, action) => {
      const { todayDate } = action.payload;
      const { lastCleanDate } = state.trips;
      if (!lastCleanDate || lastCleanDate >= todayDate) return;
      const allTrips = state.trips;
      for (const key of Object.keys(allTrips)) {
        if (allTrips[key].date < todayDate) delete allTrips[key];
      }
      state.trips = { ...allTrips, lastCleanDate: todayDate };
    },
  },
});
export const getTripInfo = (tripId: string) => (state: RootState) =>
  state?.[REDUCER_NAME]?.trips?.[tripId];
export const getTripDirectionality = (tripId: string) => (state: RootState) =>
  state?.[REDUCER_NAME]?.trips?.[tripId]?.directionality;
export const isTripActive = (tripId: string) => (state: RootState) =>
  state?.[REDUCER_NAME]?.trips?.[tripId]?.status === TripStatus.ACTIVE ||
  state?.[REDUCER_NAME]?.trips?.[tripId]?.status ===
    TripStatus.PARTIAL_COMPLETED;
export const getStopDetails =
  (tripId: string, stopId: any) => (state: RootState) =>
    state?.[REDUCER_NAME]?.trips?.[tripId]?.stops?.filter(
      ({ id }) => id === stopId
    )[0];

export const {
  setTripsMainDetails,
  setStudentTempStatus,
  removeStudentTempStatus,
  updateStudentData,
  updateTripData,
  removeOldTrips,
} = tripSlice.actions;

const reducer = { [REDUCER_NAME]: tripSlice.reducer };

// exporting the reducer here, as we need to add this to the store
export default reducer;
